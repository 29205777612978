import { memo, useCallback } from 'react'

import { Button, Group, Stack, Text } from '@mantine/core'
import { useAccount } from 'wagmi'

import { useRegisterWallet } from '../../queries/wallet'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { MismatchedWallet, useIsMismatchedWallet } from '../MismatchedWallet'
import { SwitchWalletButton } from '../SwitchWalletButton'

export const PanelRegisterWallet = memo<{ onCompleted: () => void }>(
  function PanelRegisterWallet({ onCompleted }) {
    const {
      mutateAsync: register_wallet,
      isPending,
      error,
    } = useRegisterWallet()
    const account = useAccount()
    const mismatch = useIsMismatchedWallet()

    const to_register_address = account.address

    const onClick = useCallback(() => {
      if (to_register_address == null) return

      register_wallet({
        address: to_register_address,
        provider: account.connector?.name ?? 'unknown',
      })
        .then(() => onCompleted())
        .catch(console.error)
    }, [
      account.connector?.name,
      onCompleted,
      register_wallet,
      to_register_address,
    ])

    const error_message: string = error?.message ?? ''

    return (
      <Stack maw="400px">
        <Text>
          Registering your wallet will connect it to your authorizations to use
          Fission&apos;s real world asset tokens
        </Text>
        {error_message && (
          <Text c="red" pb="lg" role="status">
            Wallet error: {error_message}
          </Text>
        )}
        <Group>
          <Text size="sm">Connected:</Text>
          <Text size="sm">{to_register_address || 'connect wallet first'}</Text>
        </Group>
        {mismatch.is_mismatched ? (
          <Stack>
            <SwitchWalletButton message="Switch Wallet" />
            <MismatchedWallet />
          </Stack>
        ) : (
          <>
            {to_register_address ? (
              <Stack>
                <Button loading={isPending} onClick={onClick}>
                  Register Wallet
                </Button>
                <Group>
                  <SwitchWalletButton
                    size="sm"
                    message="Switch Wallet"
                    variant="subtle"
                  />
                </Group>
              </Stack>
            ) : (
              <ConnectWalletButton message="Connect wallet first" />
            )}
          </>
        )}
      </Stack>
    )
  },
)

export const useSkipRegisterWallet = () => {
  const mismatch = useIsMismatchedWallet()

  if (mismatch.isLoading) return false
  return !mismatch.is_mismatched && mismatch.registered_address != null
}
