/* eslint-disable complexity */
import { Token } from '@uniswap/sdk-core'
import {
  type FeeAmount,
  Pool,
  Position,
  TickMath,
  tickToPrice,
} from '@uniswap/v3-sdk'
import { TICK_SPACINGS } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'

import { findCurrencyAmount } from '../helpers/block_helpers'

// input
export type GraphTick = {
  tickIdx: string
  liquidityGross: string
  liquidityNet: string
}

// output
export type BarChartTick = {
  id: number
  token_price: number
  usdc_tokens: number
  token_tokens: number
  token_in_usdc: number
  isCurrent: boolean
}

// intermediate
export type TickProcessed = {
  tickIdx: number
  liquidityActive: JSBI
  liquidityNet: JSBI
  price0: number
  price1: number
  isCurrent: boolean
}

export function createBarChartTicks({
  tickCurrent,
  poolSqrtPrice,
  poolLiquidity,
  feeTier,
  token0,
  token1,
  numSurroundingTicks,
  graphTicks,
}: {
  tickCurrent: string
  poolSqrtPrice: string
  poolLiquidity: string
  feeTier: string
  token0: {
    id: string
    decimals: string
    symbol: string
  }
  token1: {
    id: string
    decimals: string
    symbol: string
  }
  numSurroundingTicks: number
  graphTicks: GraphTick[]
}): BarChartTick[] {
  const feeAmount: FeeAmount = Number(feeTier) as FeeAmount
  const tickSpacing: number = TICK_SPACINGS[feeAmount]

  return processTicks({
    tickCurrent: Number(tickCurrent),
    poolLiquidity: JSBI.BigInt(poolLiquidity),
    poolSqrtPrice: JSBI.BigInt(poolSqrtPrice),
    feeAmount,
    tickSpacing,
    token0: new Token(
      1,
      token0.id,
      Number(token0.decimals),
      token0.symbol.toUpperCase(),
    ),
    token1: new Token(
      1,
      token1.id,
      Number(token1.decimals),
      token1.symbol.toUpperCase(),
    ),
    numSurroundingTicks,
    graphTicks,
  })
}

const to_bar_tick =
  ({ pool }: { pool: Pool }) =>
  (tick: TickProcessed): BarChartTick => {
    const tickSpacing: number = TICK_SPACINGS[pool.fee]
    const position = new Position({
      pool,
      liquidity: tick.liquidityActive.toString(),
      tickLower: tick.tickIdx,
      tickUpper: tick.tickIdx + tickSpacing,
    })

    const token_usdc =
      pool.token0.symbol == 'USDC' ? pool.token1Price : pool.token0Price
    const token_price = pool.token0.symbol == 'USDC' ? tick.price1 : tick.price0

    const token_amount = findCurrencyAmount({
      symbol: 'USDC',
      not: true,
      pair: [position.amount0, position.amount1],
    })
    const usdc_amount = findCurrencyAmount({
      symbol: 'USDC',
      pair: [position.amount0, position.amount1],
    })

    return {
      id: tick.tickIdx,
      token_price,
      usdc_tokens: Number(usdc_amount.toSignificant(6)),
      token_tokens: Number(token_amount.toSignificant(6)),
      token_in_usdc: Number(token_amount.multiply(token_usdc).toSignificant(6)),
      isCurrent: tick.isCurrent,
    }
  }

function processTicks({
  tickCurrent,
  poolSqrtPrice,
  poolLiquidity,
  feeAmount,
  tickSpacing,
  token0,
  token1,
  numSurroundingTicks,
  graphTicks,
}: {
  tickCurrent: number
  poolLiquidity: JSBI
  poolSqrtPrice: JSBI
  feeAmount: number
  tickSpacing: number
  token0: Token
  token1: Token
  numSurroundingTicks: number
  graphTicks: GraphTick[]
}): BarChartTick[] {
  const tickIdxToTickDictionary: Record<string, GraphTick> = Object.fromEntries(
    graphTicks.map((graphTick) => [graphTick.tickIdx, graphTick]),
  )

  const liquidity = poolLiquidity

  let activeTickIdx = Math.floor(tickCurrent / tickSpacing) * tickSpacing

  if (activeTickIdx <= TickMath.MIN_TICK) {
    activeTickIdx = TickMath.MAX_TICK
  }

  const activeTickProcessed: TickProcessed = {
    tickIdx: activeTickIdx,
    liquidityActive: liquidity,
    liquidityNet: JSBI.BigInt(0),
    price0: parseFloat(
      tickToPrice(token0, token1, activeTickIdx).toSignificant(6),
    ),
    price1: parseFloat(
      tickToPrice(token1, token0, activeTickIdx).toSignificant(6),
    ),
    isCurrent: true,
  }

  const activeTick = tickIdxToTickDictionary[activeTickIdx]
  if (activeTick) {
    activeTickProcessed.liquidityNet = JSBI.BigInt(activeTick.liquidityNet)
  }

  const subsequentTicks: TickProcessed[] = computeInitializedTicks({
    activeTickProcessed,
    numSurroundingTicks,
    tickSpacing,
    direction: 'ASC',
    token0,
    token1,
    tickIdxToTickDictionary,
  })

  const previousTicks: TickProcessed[] = computeInitializedTicks({
    activeTickProcessed,
    numSurroundingTicks,
    tickSpacing,
    direction: 'DESC',
    token0,
    token1,
    tickIdxToTickDictionary,
  })

  const pool = new Pool(
    token0,
    token1,
    feeAmount,
    poolSqrtPrice,
    poolLiquidity,
    tickCurrent,
  )

  return previousTicks
    .concat(activeTickProcessed)
    .concat(subsequentTicks)
    .map(to_bar_tick({ pool }))
}

function computeInitializedTicks({
  activeTickProcessed,
  numSurroundingTicks,
  tickSpacing,
  direction,
  token0,
  token1,
  tickIdxToTickDictionary,
}: {
  activeTickProcessed: TickProcessed
  numSurroundingTicks: number
  tickSpacing: number
  direction: 'ASC' | 'DESC'
  token0: Token
  token1: Token
  tickIdxToTickDictionary: Record<string, GraphTick>
}): TickProcessed[] {
  let previousTickProcessed: TickProcessed = {
    ...activeTickProcessed,
  }

  let ticksProcessed: TickProcessed[] = []
  for (let i = 0; i < numSurroundingTicks; i++) {
    const currentTickIdx =
      direction === 'ASC'
        ? previousTickProcessed.tickIdx + tickSpacing
        : previousTickProcessed.tickIdx - tickSpacing

    if (
      currentTickIdx < TickMath.MIN_TICK ||
      currentTickIdx > TickMath.MAX_TICK
    ) {
      break
    }

    const currentTickProcessed: TickProcessed = {
      tickIdx: currentTickIdx,
      liquidityActive: previousTickProcessed.liquidityActive,
      liquidityNet: JSBI.BigInt(0),
      price0: parseFloat(
        tickToPrice(token0, token1, currentTickIdx).toSignificant(18),
      ),
      price1: parseFloat(
        tickToPrice(token1, token0, currentTickIdx).toSignificant(18),
      ),
      isCurrent: false,
    }

    const currentInitializedTick =
      tickIdxToTickDictionary[currentTickIdx.toString()]
    if (currentInitializedTick) {
      currentTickProcessed.liquidityNet = JSBI.BigInt(
        currentInitializedTick.liquidityNet,
      )
    }

    if (direction == 'ASC' && currentInitializedTick) {
      currentTickProcessed.liquidityActive = JSBI.add(
        previousTickProcessed.liquidityActive,
        JSBI.BigInt(currentInitializedTick.liquidityNet),
      )
    } else if (
      direction == 'DESC' &&
      JSBI.notEqual(previousTickProcessed.liquidityNet, JSBI.BigInt(0))
    ) {
      // We are iterating descending, so look at the previous tick and apply any net liquidity.
      currentTickProcessed.liquidityActive = JSBI.subtract(
        previousTickProcessed.liquidityActive,
        previousTickProcessed.liquidityNet,
      )
    }

    ticksProcessed.push(currentTickProcessed)
    previousTickProcessed = currentTickProcessed
  }

  if (direction == 'DESC') {
    ticksProcessed = ticksProcessed.reverse()
  }

  return ticksProcessed
}
