import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_get_kyc_access_token = graphql(`
  query get_kyc_access_token {
    me {
      kyc {
        access_token
      }
    }
  }
`)

export const query_get_kyc_status = graphql(`
  query get_kyc_status {
    me {
      kyc {
        verification {
          passed
        }
      }
    }
  }
`)
