import { memo, useCallback, useEffect, useState } from 'react'

import { Anchor, Button, Stack, Switch, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { usePostHog } from 'posthog-js/react'
import { z } from 'zod'

import { useGetFlags } from '../../hooks/flags'
import { useGetKycStatus } from '../../queries/kyc'
import {
  useGetLocationAttestation,
  useSaveLocationAttestation,
} from '../../queries/location'

const schema = z.object({
  agreed: z.boolean(),
  confirm: z.boolean(),
})

export const PanelLocationVerify = memo<{ onCompleted: () => void }>(
  // eslint-disable-next-line max-lines-per-function, complexity
  function PanelLocationVerify({ onCompleted }) {
    const form = useForm({
      initialValues: {
        agreed: false,
        confirm: false,
      },
      validateInputOnChange: true,
      validate: zodResolver(schema),
    })
    const { mutate, isPending } = useSaveLocationAttestation()
    const { data: location_attestation } = useGetLocationAttestation()
    const posthog = usePostHog()
    const [joined_waiting_list, set_joined] = useState(false)

    useEffect(() => {
      if (location_attestation?.passed) {
        onCompleted()
      }
    }, [location_attestation?.passed, onCompleted])

    const onJoin = useCallback(() => {
      set_joined(true)
      posthog.capture('waiting list', {
        type: 'us citizen',
      })
    }, [posthog])

    const onSubmit = (
      values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
    ) => {
      mutate(values)
    }

    const did_not_pass =
      location_attestation?.passed == false &&
      location_attestation.status == 'completed'

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack maw="400px">
          <Text>
            Fission&apos;s real world asset tokens have legal restrictions
          </Text>
          <Switch
            size="xl"
            onLabel="NO"
            offLabel="YES"
            label="Are you a US citizen?"
            disabled={isPending || did_not_pass}
            {...form.getInputProps('agreed')}
          />
          <Switch
            size="xl"
            onLabel="YES"
            offLabel="NO"
            label="Agree with above answer?"
            disabled={isPending || did_not_pass}
            {...form.getInputProps('confirm')}
          />
          <Button
            type="submit"
            size="lg"
            variant="filled"
            loading={isPending}
            disabled={form.getValues().confirm == false || did_not_pass}
          >
            Submit Answer
          </Button>
          {did_not_pass && (
            <Stack gap="xs" ta="center">
              <Text c="red" role="status">
                Location or Citizenship not allowed at the moment.
              </Text>
              <Text>
                If you have any questions or issues please reach out to us at{' '}
                <Anchor href="mailto:hello@fission.xyz">
                  hello@fission.xyz
                </Anchor>
                .
              </Text>
              <Button onClick={onJoin} disabled={joined_waiting_list}>
                {joined_waiting_list ? 'Joined!' : 'Join waiting list'}
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    )
  },
)

export const useSkipLocationVerify = () => {
  const { is_demo_site } = useGetFlags()
  const location_query = useGetLocationAttestation()
  const kyc_query = useGetKycStatus()

  if (is_demo_site) return true
  if (location_query.isLoading || kyc_query.isLoading) return false
  return Boolean(location_query.data?.passed || kyc_query.data?.passed)
}
