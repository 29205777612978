import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'

import { graphql_call } from '@repo/common/queries/helpers/server_query'

import { useAuth } from '../auth'

import { mutation_register_wallet, query_get_wallet } from './wallet_graphql'

export const keys = createQueryKeyStore({
  wallet: {
    registered: null,
  },
})

type WalletType = {
  address: CryptoAddress
  provider: string
}

export function useGetRegisteredWallet() {
  const auth = useAuth()

  return useQuery<WalletType | null>({
    queryKey: keys.wallet.registered.queryKey,
    queryFn: async () => {
      const results = await graphql_call({ query: query_get_wallet })
      return results?.wallet || null
    },
    enabled: auth.data?.is_authenticated,
  })
}

export function useRegisterWallet() {
  const posthog = usePostHog()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: { address: CryptoAddress; provider: string }) => {
      const result = await graphql_call({
        query: mutation_register_wallet,
        variables: {
          input,
        },
      })
      posthog.capture('$set', {
        $set: {
          wallet_address: input.address,
          wallet_provider: input.provider,
        },
      })
      return result
    },
    onMutate: async (input) => {
      await queryClient.cancelQueries(keys.wallet.registered)

      queryClient.setQueryData<WalletType | null>(
        keys.wallet.registered.queryKey,
        input,
      )
    },
    onError: () => {
      queryClient.setQueryData(keys.wallet.registered.queryKey, null)
    },
    onSettled: () => {
      void queryClient.invalidateQueries(keys.wallet.registered)
    },
  })
}
