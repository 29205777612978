import { memo } from 'react'

import { type ButtonProps } from '@mantine/core'
import * as _ from 'lodash-es'
import { useAccount, useConnect } from 'wagmi'

import { SelectConnectionButton } from '../SelectConnectionButton'

export const ConnectWalletButton = memo<
  Omit<ButtonProps, 'onClick'> & { message?: string }
>(function ConnectWalletButton({ message = 'Connect Wallet', ...props }) {
  const { connect, error, isPending } = useConnect()
  const account = useAccount()

  /* istanbul ignore next */
  if (error) {
    console.error({
      status: account.status,
      error,
    })
  }

  if (account.isConnected) return null

  return (
    <SelectConnectionButton
      size="md"
      message={message}
      onSelect={connect}
      isPending={isPending}
      {...props}
    >
      {message}
    </SelectConnectionButton>
  )
})
