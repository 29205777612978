import { memo } from 'react'

import { Anchor, Stack, Text } from '@mantine/core'
import { isAddressEqual } from 'viem'
import { useAccount } from 'wagmi'

import { useGetRegisteredWallet } from '../../queries/wallet'

export const MismatchedWallet = memo(function MismatchedWallet() {
  const registered = useGetRegisteredWallet()
  return (
    <Stack gap="sm" w="100%">
      <Text size="md" ta="center">
        Registered wallet is: {registered.data?.address}
      </Text>
      <Text size="sm" ta="center">
        If you have any questions or issues please reach out to us at{' '}
        <Anchor href="mailto:hello@fission.xyz">hello@fission.xyz</Anchor>.
      </Text>
    </Stack>
  )
})

export function useIsMismatchedWallet() {
  const account = useAccount()

  const registered = useGetRegisteredWallet()

  return {
    registered_address: registered.data?.address,
    is_mismatched:
      account.address != null &&
      registered.data != null &&
      !isAddressEqual(registered.data.address, account.address),
    isLoading: registered.isLoading,
  }
}
