import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import type { ResultOf } from 'gql.tada'

import { graphql_call } from '@repo/common/queries/helpers/server_query'

import { useGetFlags } from '../../hooks/flags'
import { useIsAuthenticated } from '../auth'
import type { InputsOf } from '../helpers/utilities'

import {
  mutation_save_location_attestation,
  query_get_location_attestation,
} from './location_graphql'

export const keys = createQueryKeyStore({
  location: {
    verification: null,
  },
})

export type VerificationType = ResultOf<
  typeof query_get_location_attestation
>['me']['location_attestation']['verification']

export function useGetLocationAttestation() {
  const { is_demo_site } = useGetFlags()
  const queryClient = useQueryClient()
  const is_auth = useIsAuthenticated()

  const enabled = is_auth

  return useQuery<VerificationType>({
    queryKey: keys.location.verification.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          if (is_demo_site) {
            return (
              queryClient.getQueryData<VerificationType>(
                keys.location.verification.queryKey,
              ) ??
              ({
                passed: false,
                status: 'missing',
              } satisfies VerificationType)
            )
          }

          const result = await graphql_call({
            query: query_get_location_attestation,
          })
          return result!.location_attestation.verification
        },
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export function useSaveLocationAttestation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      agreed,
    }: InputsOf<typeof mutation_save_location_attestation>) => {
      return graphql_call({
        query: mutation_save_location_attestation,
        variables: { input: { agreed } },
      })
    },
    onSettled: (result) => {
      if (result?.passed != null) {
        queryClient.setQueryData<VerificationType>(
          keys.location.verification.queryKey,
          {
            passed: result.passed,
            status: 'completed',
          },
        )
      }
    },
  })
}
