import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_get_location_attestation = graphql(`
  query get_location_attestation {
    me {
      location_attestation {
        verification {
          passed
          status
        }
      }
    }
  }
`)

export const mutation_save_location_attestation = graphql(`
  mutation save_location_attestation($input: LocationAttestationInput!) {
    save_location_attestation(input: $input) {
      passed
    }
  }
`)
