import { graphql } from '@repo/common/queries/helpers/server_query'

export const query_get_wallet = graphql(`
  query get_wallet {
    me {
      wallet {
        address
        provider
      }
    }
  }
`)

export const mutation_register_wallet = graphql(`
  mutation register_wallet($input: RegisterWalletInput!) {
    register_wallet(input: $input) {
      added_at
    }
  }
`)
