import { memo } from 'react'

import { Button, type ButtonProps } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useAuth } from '@repo/common/queries/auth'

import { FissionModal } from '../FissionModal'
import {
  PanelRegisterWallet,
  useSkipRegisterWallet,
} from '../PanelRegisterWallet'

export { useSkipRegisterWallet }

export const RegisterWalletButton = memo<
  Omit<ButtonProps, 'onClick'> & {
    message?: string
  }
>(function RegisterWalletButton({ message = 'Register Wallet', ...props }) {
  const [opened, disclosure_handlers] = useDisclosure(false)
  const { isLoading } = useAuth()

  return (
    <>
      <FissionModal
        closeOnClickOutside={true}
        closeOnEscape={true}
        withCloseButton={true}
        opened={opened}
        onClose={disclosure_handlers.close}
        title={message}
      >
        <PanelRegisterWallet onCompleted={disclosure_handlers.close} />
      </FissionModal>
      <Button
        size="md"
        {...props}
        onClick={disclosure_handlers.open}
        loading={isLoading}
      >
        {message}
      </Button>
    </>
  )
})
