import { memo } from 'react'

import { type ButtonProps } from '@mantine/core'
import * as _ from 'lodash-es'
import { useAccount, useSwitchAccount } from 'wagmi'

import { SelectConnectionButton } from '../SelectConnectionButton'

export const SwitchWalletButton = memo<
  Omit<ButtonProps, 'onClick'> & { message?: string }
>(function SwitchWalletButton({ message = 'Switch Wallet', ...props }) {
  const { switchAccount, error, isPending } = useSwitchAccount()
  const account = useAccount()

  /* istanbul ignore next */
  if (error) {
    console.error({
      status: account.status,
      error,
    })
  }

  return (
    <SelectConnectionButton
      size="md"
      message={message}
      onSelect={switchAccount}
      isPending={isPending}
      {...props}
    >
      {message}
    </SelectConnectionButton>
  )
})
