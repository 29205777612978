import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import type { ResultOf } from 'gql.tada'

import { graphql_call } from '@repo/common/queries/helpers/server_query'

import { useGetFlags } from '../../hooks/flags'
import { useIsAuthenticated } from '../auth'

import { query_get_kyc_access_token, query_get_kyc_status } from './kyc_graphql'

export const keys = createQueryKeyStore({
  kyc: {
    access_token: null,
    verification: null,
  },
})

export type KycStatusType = ResultOf<
  typeof query_get_kyc_status
>['me']['kyc']['verification']

export function useGetKycStatus() {
  const { is_demo_site } = useGetFlags()
  const queryClient = useQueryClient()
  const is_auth = useIsAuthenticated()

  const enabled = is_auth

  return useQuery({
    queryKey: keys.kyc.verification.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          if (is_demo_site) {
            return (
              queryClient.getQueryData<KycStatusType>(
                keys.kyc.verification.queryKey,
              ) ??
              ({
                passed: false,
              } satisfies KycStatusType)
            )
          }

          const result = await graphql_call({
            query: query_get_kyc_status,
          })
          return result?.kyc.verification
        },
    gcTime: Infinity,
  })
}

async function get_token() {
  const response = await graphql_call({ query: query_get_kyc_access_token })
  return response?.kyc.access_token ?? null
}

export function useGetKycAccessToken() {
  const queryClient = useQueryClient()
  // TODO check for user logged in

  const q = {
    queryKey: keys.kyc.access_token.queryKey,
    queryFn: get_token,
  }
  const results = useQuery({
    ...q,
    staleTime: 590 * 1000, // almost 600 which is the backend ttl
    gcTime: Infinity,
  })

  const refreshToken = async () => {
    const token = await queryClient.fetchQuery({
      queryKey: keys.kyc.access_token.queryKey,
      queryFn: get_token,
    })
    return token ?? ''
  }

  return {
    refreshToken,
    ...results,
  }
}
