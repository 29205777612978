import type { ResultOf, TadaDocumentNode, VariablesOf } from 'gql.tada'
import type { GraphQLClient } from 'graphql-request'
import * as _ from 'lodash-es'

export function denest_result<
  ResultType extends { [key: string]: unknown } | null,
  ItemType = ResultType extends { [key: string]: infer R } | null ? R : unknown,
>(result?: ResultType) {
  if (result == null) return undefined

  const denestKey = Object.keys(result)[0]

  return result[denestKey] as ItemType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericTypedDocumentNode = TadaDocumentNode<any, any>
export type InputsOf<T extends GenericTypedDocumentNode> =
  VariablesOf<T>['input']

export function create_graphql_call(client: GraphQLClient) {
  return async function graphql_call<
    Document extends GenericTypedDocumentNode,
  >({
    query,
    variables,
  }: {
    query: Document
    variables?: VariablesOf<Document>
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: ResultOf<Document> = await client.request({
        document: query,
        variables,
      })

      return denest_result(response)
    } catch (error_catch: unknown) {
      process_error(error_catch)
    }
  }
}

function process_error(error_catch: unknown) {
  if (!_.isError(error_catch)) {
    const error_response = error_catch as { errors?: { message: string }[] }
    const { message } = error_response?.errors?.[0] || {
      message: 'unknown',
    }

    const error = new Error(`GraphQL error: ${message}`)

    _.forEach(error_response, (value, key) => {
      if (key === 'message') return
      // @ts-expect-error restore all extra keys for logging
      error[key] = value
    })
    throw error
  }
  // clean message string
  error_catch.message = error_catch.message.split(':')[0]

  throw error_catch
}

export function create_graphql_multi_call(client: GraphQLClient) {
  return async function graphql_call<
    Document extends GenericTypedDocumentNode,
  >({
    query,
    variables,
  }: {
    query: Document
    variables?: VariablesOf<Document>
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: ResultOf<Document> = await client.request({
        document: query,
        variables,
      })

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response
    } catch (error_catch: unknown) {
      process_error(error_catch)
    }
  }
}
